body {
  margin: 0;
  font-family: PowerGrotesk-Regular, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

@font-face {
  font-family: "PowerGrotesk-Regular";
  src: url("./assets/fonts/PowerGrotesk-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #e3f7ba;
  background-color: #223013;
  padding: 0.1em 0.3em;
  border-radius: 0.2em;
  font-size: 15px;
} */

::-moz-selection {
  color: #e3f7ba;
  background: #223013;
}
::-webkit-selection {
  color: #e3f7ba;
  background: #223013;
}
::selection {
  color: #e3f7ba;
  background: #223013;
}

pre {
  margin: 6px;
}

ul {
  margin: 0;
  padding: 0 1.2em;
}

ol {
  margin: 0;
  padding: 0 1.2em;
}
