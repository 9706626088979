.typing-animation {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: #d4ff71;
  border-radius: 50%;
  margin: 0 5px;
  animation: dot-animation 1s infinite;
}

.dot1 {
  animation-delay: 0s;
}

.dot2 {
  animation-delay: 0.2s;
}

.dot3 {
  animation-delay: 0.4s;
}

@keyframes dot-animation {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
}
